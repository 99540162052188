import { NavBar } from "../components/general/NavBar"
import { Footer } from "../components/general/Footer"
import { Container } from "react-bootstrap"
import Header from "components/Profile/Header"
import Menu from "components/Profile/Menu"
import { useSelector } from "react-redux"
import NotAuthorized from "./NotAuthorized"
import { useEffect, useState } from "react"
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

export default function Profile() {

  const { user: authUser } = useSelector(x => x.auth)
  const url = process.env.REACT_APP_URL
  const params = useParams()
  const userId = params.userId
  // console.log(userId)

  const [profile, setProfile] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getProfile = async () => {

    setIsLoading(true)
    // console.log(uid)

    await fetch(`${url}/user/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.token
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('(' + res.status + ') We could not retrieve the user')
        }
        setIsLoading(false)
        return res.json()
      })
      .then(resData => {
        setProfile(resData)
      })

    setIsLoading(false)
  }

  useEffect(() => {
    getProfile()
    //eslint-disable-next-line
  }, [userId])

  if (!authUser) {
    return <NotAuthorized />
  }
  else {
    return (
      <>
        {
          isLoading ?
            <Spinner animation='border' variant='danger' />
            :
            <>
              <NavBar pagename='Profile' />
              <section className="profile">
                <Container>
                  <Header profile={profile} />
                  <Menu />
                </Container>
              </section>
              <Footer />
            </>
        }
      </>
    )
  }
}