import { Row, Col, Form, InputGroup, Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector } from "react-redux"
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
const date = require('date-and-time');

export default function AgendaItemAddNewModal({ meetingId, getAgenda, prevPos = null }) {

    const { user: authUser } = useSelector(x => x.auth)
    // console.log(prevPos)

    const url = process.env.REACT_APP_URL

    // const [startTime, setStartTime] = useState('')
    const [timeGreen, setTimeGreen] = useState('')
    const [timeAmber, setTimeAmber] = useState('')
    const [timeRed, setTimeRed] = useState('')
    const [totalTime, setTotalTime] = useState('')
    const [role, setRole] = useState('')
    const [presenter, setPresenter] = useState('')
    const [event, setEvent] = useState('')
    const [eventDescription, setEventDescription] = useState('')
    // const [position, setPosition] = useState('')

    const [saveLoading, setSaveLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => setShowModal(false)
    const handleShowModal = () => setShowModal(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        setSaveLoading(true)
        let startTime = ''
        let position = ''
        if (!prevPos) {
            startTime = "18:30"
            position = 1
        }
        else {
            const parts = prevPos.startTime.split(':')
            const convertedDate = new Date()
            convertedDate.setHours(parts[0])
            convertedDate.setMinutes(parts[1])
            const prevTotalTime = prevPos.totalTime
            const newStartTimeDate = date.addMinutes(convertedDate, prevTotalTime.split(':')[0])
            const newHours = String(newStartTimeDate.getHours())
            const newMinutes = (String(newStartTimeDate.getMinutes()) < 10) ? `0${String(newStartTimeDate.getMinutes())}` : String(newStartTimeDate.getMinutes())
            const newStartTime = `${newHours}:${newMinutes}`

            startTime = newStartTime
            position = Number(prevPos.position) + 1
        }
        const validate = true;
        if (validate === true) {
            fetch(url + '/agendaItem/' + meetingId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authUser.token
                },
                body: JSON.stringify({
                    startTime: startTime,
                    timeGreen: timeGreen,
                    timeAmber: timeAmber,
                    timeRed: timeRed,
                    totalTime: totalTime,
                    role: role,
                    presenter: presenter,
                    event: event,
                    eventDescription: eventDescription,
                    position: position
                })
            })
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        // console.log('add Item error issue: ')
                        // console.log(res)
                        throw new Error('(' + res.status + ') We could not save your new agenda Item')

                    }
                    return res.json()
                })
                .then(resData => {
                    // console.log("response JSON: "+ resData)
                    toast.success('Your AgendaItem ' + event + ' has been created', { position: 'bottom-left' })
                    setSaveLoading(false)
                    getAgenda()
                    handleCloseModal()
                })
                .catch(err => {
                    toast.error('' + err, { position: 'bottom-left' })
                    setSaveLoading(false)
                })
        }

    }

    return (
        <>
            <Row>
                <Col className='addAgendaItem'>
                    <h2 onClick={handleShowModal}>Add Item</h2>
                </Col>
            </Row>
            <Modal centered show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'} >

                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Agenda Item
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Role'
                                aria-label='Role'
                                name='role'
                                onChange={e => setRole(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Presenter'
                                aria-label='Presenter'
                                name='presenter'
                                onChange={e => setPresenter(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Event'
                                aria-label='Event'
                                name='event'
                                onChange={e => setEvent(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control as="textarea"
                                className='formInput'
                                placeholder='Event Description'
                                aria-label='Event Description'
                                name='eventDescription'
                                onChange={e => setEventDescription(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Green: mm:ss'
                                aria-label='Time Green'
                                name='timeGreen'
                                onChange={e => setTimeGreen(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Amber: mm:ss'
                                aria-label='time Amber'
                                name='timeAmber'
                                onChange={e => setTimeAmber(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Red: mm:ss'
                                aria-label='Time Red'
                                name='timeRed'
                                onChange={e => setTimeRed(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Total Time: mm:ss *'
                                aria-label='Total Time *'
                                name='totalTime'
                                onChange={e => setTotalTime(e.target.value)}
                            />
                        </InputGroup>
                        {/* <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Position'
                                aria-label='Position'
                                name='position'
                                onChange={e => setPosition(e.target.value)}
                            />
                        </InputGroup> */}
                    </Modal.Body>
                    <Modal.Footer>
                        {saveLoading
                            ?
                            <>
                                <Spinner animation='border' variant='danger' />
                                Adding Agenda Item ...
                            </>
                            :
                            <>
                                <Button variant="success" type='submit' onClick={handleSubmit}>
                                    Add Agenda Item
                                </Button>
                                <Button variant="danger" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}