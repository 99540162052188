import { Card, Row, Col, Container, Image, Form } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Spinner } from 'react-bootstrap'
import { useEffect, useState } from "react"
import UserAdd from "./crudModals/UserAdd"
import blondeAvatar from '../../assets/avatarblonde.png'
import { Link } from "react-router-dom"
import UserDelete from "./crudModals/UserDelete"

export default function ListUsers() {

  const { user: authUser } = useSelector(x => x.auth)
  const url = process.env.REACT_APP_URL

  const [isLoading, setIsLoading] = useState(true, [])
  const [userList, setUserList] = useState([])

  const getUsers = async () => {
    setIsLoading(true)
    await fetch(`${url}/users/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.token
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('(' + res.status + ') We could not retrieve the users')
        }
        return res.json()
      })
      .then(resData => {
        setUserList(resData)
      })
    setIsLoading(false)
  }

  useEffect(() => {
    getUsers()
    //eslint-disable-next-line
  }, [])

  return (
    <section >
      {
        isLoading ?
          <Spinner animation='border' variant='danger' />
          :
          <>
            <h5>Users List</h5>
            <Card data-bs-theme={'dark'} className="mt-3 mb-3">
              <Card.Header>
                <Row className="mt-3 mb-3">
                  <Col xs={6}>
                  </Col>
                  <Col >
                    <UserAdd getUsers={getUsers} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Container>
                  {userList.map((user) => (
                    <Row key={user._id} className="mt-3 mb-3">
                      <Col style={{ maxWidth: '20px' }} className="float-start mt-3">
                        <Form.Check //prettier ignore
                          type='checkbox'
                          label=''
                        />
                      </Col>
                      <Col style={{ maxWidth: '45px' }}>
                        <Link to={`/profile/${user._id}`} >
                          {
                            !user.avatar
                              ? <Image src={blondeAvatar} style={{ width: '45px' }} className="float-start" />
                              : <Image src={'data:image/png;base64, ' + user.avatar} style={{ width: '45px' }} className="float-start" />
                          }
                        </Link>
                      </Col>
                      <Col style={{ maxWidth: "370px" }}>
                        <Container>
                          <Row>
                            <Col >
                              <Link to={`/profile/${user._id}`} >
                                <h5>{user.firstName} {user.lastName}</h5>
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col><p style={{ color: 'var(--bs-secondary-text-emphasis)' }}>{user.email}</p></Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col>
                        <UserDelete user={user} getUsers={getUsers} />
                      </Col>
                    </Row>
                  ))}
                </Container>
              </Card.Body>
            </Card>
          </>
      }
    </section >
  )
}