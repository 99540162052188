// import Avatar from "./settings/Avatar";
import DeleteAccount from "./settings/DeleteAccount";
import Notifications from "./settings/Notifications";
import PasswordReset from "./settings/PasswordReset";
import UserDetails from "./settings/UserDetails";

export default function Settings() {
  const profileUser = {
    firstName: 'Marius',
    lastName: 'Terente-Cornescu',
    phoneNo: '',
    website: 'marius-c.webhop.me'
  }
  return (
    <>
      {/* <Avatar /> */}
      <UserDetails profileUser={profileUser} />
      <PasswordReset />
      <Notifications />
      <DeleteAccount />
    </>
  )
}