import { NavBar } from "components/general/NavBar";
import { Footer } from "components/general/Footer";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux"
import ListUsers from "components/Users/ListUsers";
import NotAuthorized from "./NotAuthorized"


export default function UserList() {

  const { user: authUser } = useSelector(x => x.auth)

  if (!authUser) {
    return <NotAuthorized />
  }
  else {
    return (
      <>
        <NavBar pagename='Profile' />
        <section className="profile">
          <Container>
            <ListUsers />
          </Container>
        </section>
        <Footer />
      </>
    )
  }
}