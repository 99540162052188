import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment'


import { useState } from 'react'

// import './FormModal.css'

export default function MeetingAddNewModal({ getMeetings = null }) {

    const url = process.env.REACT_APP_URL
    const { user: authUser } = useSelector(x => x.auth)

    const nextTuesday = new Date()
    nextTuesday.setDate(nextTuesday.getDate() + (((2 + 7 - nextTuesday.getDay()) % 7) || 7))

    const [showNewMeetingModal, setShowNewMeetingModal] = useState(false)
    const [meetingType, setMeetingType] = useState('Regular');
    const [selectedDate, setSelectedDate] = useState(nextTuesday)
    const [meetingDate, setMeetingDate] = useState(`${selectedDate.getDate()}/${selectedDate.getMonth()}/${selectedDate.getFullYear()}`)
    const [meetingTime, setMeetingTime] = useState('18:30')
    const [meetingAddress, setMeetingAddress] = useState('KŘENOVÁ 57, OBČANSKÝ KLUB')
    const [meetingNumber, setMeetingNumber] = useState(undefined)
    const [meetingTheme, setMeetingTheme] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [facebookUrl, setFacebookUrl] = useState('')
    const [youtubeUrl, setYoutubeUrl] = useState('')
    const [meetingDescription, setMeetingDescription] = useState("We're delighted to invite you to our next meeting where we will be discussing this topic. Prepared speeches, impromptu speeches, feedback, games and, after the meeting, drinks are what we are inviting you to attend to. For more details, check our website: www.brnotoastmasters.cz")

    const [saveLoading, setSaveLoading] = useState(false)
    const handleCloseNewMeetingModal = () => setShowNewMeetingModal(false)
    const handleShowNewMeetingModal = () => setShowNewMeetingModal(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        setMeetingDate(`${selectedDate.getDate()}/${selectedDate.getMonth() + 1}/${selectedDate.getFullYear()}`)
        setSaveLoading(true)
        const validate = true;
        if (validate === true) {
            fetch(url + '/meeting', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authUser.token
                },
                body: JSON.stringify({
                    meetingType: meetingType,
                    meetingDate: meetingDate,
                    meetingTime: meetingTime,
                    meetingAddress: meetingAddress,
                    meetingNumber: meetingNumber,
                    meetingTheme: meetingTheme,
                    imageUrl: imageUrl,
                    facebookUrl: facebookUrl,
                    youtubeUrl: youtubeUrl,
                    meetingDescription: meetingDescription
                })
            })
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error('(' + res.status + ') We could not save your new meeting')
                    }
                    return res.json()
                })
                .then(resData => {
                    console.log("response JSON: " + resData)
                    toast.success('Your meeting ' + meetingTheme + ' has been created', { position: 'bottom-left' })
                    setSaveLoading(false)
                    if (getMeetings) getMeetings()
                    handleCloseNewMeetingModal()
                })
                .catch(err => {
                    toast.error('' + err, { position: 'bottom-left' })
                    setSaveLoading(false)
                    if (getMeetings) getMeetings()
                })
        }

    }

    return (
        <>
            <Button onClick={handleShowNewMeetingModal} className='mt-30 float-end' variant='success'>+ Add Meeting</Button>
            <Modal centered show={showNewMeetingModal} onHide={handleCloseNewMeetingModal} className='text-center' data-bs-theme={'dark'}>

                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Meeting
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Form.Select
                            aria-label='Meeting Type'
                            className='mb-3 formInput'
                            name='meetingType'
                            value={meetingType}
                            onChange={e => setMeetingType(e.target.value)}
                        >
                            <option value='Regular' className='formInput'>Regular Meeting</option>
                            <option value='Committee' className='formInput'>Committee Meeting</option>
                            <option value='Contest' className='formInput'>Contest Meeting</option>
                            <option value='Other' className='formInput'>Other Event</option>
                        </Form.Select>
                        <InputGroup className='mb-3'>
                            <DatePicker
                                wrapperClassName='formInput'
                                showIcon
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}

                            /> &nbsp;&nbsp;&nbsp;&nbsp;
                            <Form.Control
                                className='formInput'
                                placeholder='Time: HH:mm'
                                aria-label='Time'
                                name='meetingTime'
                                value={meetingTime}
                                onChange={e => setMeetingTime(e.target.value)}
                            />
                        </InputGroup>
                        {/* <InputGroup className='mb-3'>
                            
                        </InputGroup> */}
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='red-border'
                                placeholder='Location'
                                aria-label='Location'
                                name='meetingAddress'
                                value={meetingAddress}
                                onChange={e => setMeetingAddress(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Number'
                                aria-label='meetingNo'
                                name='meetingNumber'
                                value={meetingNumber}
                                onChange={e => setMeetingNumber(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Theme'
                                aria-label='meeting Theme'
                                name='meetingTheme'
                                value={meetingTheme}
                                onChange={e => setMeetingTheme(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control as="textarea"
                                className='formInput'
                                placeholder='Meeting Description'
                                aria-label='meeting Description'
                                rows={5}
                                name='meetingDescription'
                                value={meetingDescription}
                                onChange={e => setMeetingDescription(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='meeting Picture'
                                aria-label='meeting Picture'
                                name='imageUrl'
                                value={imageUrl}
                                onChange={e => setImageUrl(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='meeting facebook event url'
                                aria-label='meeting facebook event url'
                                name='facebookUrl'
                                value={facebookUrl}
                                onChange={e => setFacebookUrl(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Youtube Recording'
                                aria-label='meeting Youtube Recording'
                                name='youtubeUrl'
                                value={youtubeUrl}
                                onChange={e => setYoutubeUrl(e.target.value)}
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        {saveLoading
                            ?
                            'Adding meeting ... '
                            :
                            <>
                                <Button variant="success" type='submit' onClick={handleSubmit}>
                                    Add Meeting
                                </Button>
                                <Button variant="danger" onClick={handleCloseNewMeetingModal}>
                                    Cancel
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}