import { NavBar } from "components/general/NavBar";
import { Footer } from "components/general/Footer";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux"

import NotAuthorized from "./NotAuthorized"


export default function WebPermissions() {

  const { user: authUser } = useSelector(x => x.auth)

  if (!authUser) {
    return <NotAuthorized />
  }
  else {
    return (
      <>
        <NavBar pagename='Admin' />
        <section className="profile">
          <Container>

          </Container>
        </section>
        <Footer />
      </>
    )
  }
}